<template>
  <table
    style="background: white; padding: 10px"
    class="signature"
  >
    <tbody>
      <tr>
        <td>
          <!-- Top -->
          <BlocTop :main-fields="mainFields" />
          <!-- Middle -->
          <table>
            <tr>
              <td
                align="left"
                style="border: none; border-bottom: 1px solid #eeeeee; width: 400px; height: 5px; line-height: 0;"
              >
                &nbsp;
              </td>
            </tr>
          </table>
          <table
            cellspacing="0"
            cellpadding="0"
            border="0"
            role="presentation"
            style="font-size: 0; line-height: 1.5"
          >
            <tbody>
              <tr>
                <td>
                  <table
                    cellspacing="0"
                    cellpadding="3"
                    border="0"
                    role="presentation"
                    style="margin-top: 5px; font-size: 0;"
                  >
                    <!-- Calendar -->
                    <Calendar />

                    <!-- Ticket -->
                    <Ticket />

                    <!-- Email -->
                    <Email :main-fields="mainFields" />

                    <!-- Phone -->
                    <Phone :main-fields="mainFields" />

                    <!-- Office -->
                    <Office />

                    <!-- Website -->
                    <Website />
                  </table>
                </td>
              </tr>
              <!-- Social icons -->
              <Social />
            </tbody>
          </table>
          <table v-if="!options.banner.show">
            <tr>
              <td
                align="left"
                style="border: none; border-bottom: 1px solid #eeeeee; width: 400px; line-height: 0;"
              >
                &nbsp;
              </td>
            </tr>
          </table>
          <!-- Banner -->
          <Banner />
          <!-- Disclaimer -->
          <Disclaimer />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import EmailTemplate from './emailTemplate'
import Disclaimer from './Disclaimer'
import Banner from './Banner'
import Social from './Social'
import BlocTop from './BlocTop'
import Calendar from './Calendar'
import Ticket from './Ticket'
import Email from './Email'
import Phone from './Phone'
import Office from './Office'
import Website from './Website'

export default {
  components: {
    Disclaimer,
    Banner,
    Social,
    BlocTop,
    Calendar,
    Ticket,
    Email,
    Phone,
    Office,
    Website
  },
  extends: EmailTemplate
}
</script>
