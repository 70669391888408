<template>
  <table
    v-if="options.banner.show"
    cellspacing="0"
    cellpadding="0"
    border="0"
    role="presentation"
    style="font-size: 0; line-height: 1.5"
  >
    <tbody>
      <tr v-if="options.banner.default === 'netrivals'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/digital-shelf-analytics/"
          >
            <img
              :src="`${options.baseUrl}/img/ban/banner-mail-netRivals.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'connect-paris-2022'">
        <td width="550">
          <a
            target="_blank"
            href="https://hello.lengow.com/fr/event/connect-day/paris/2022"
          >
            <img
              src="../../../public/img/ban/lengow-connect-paris-2022.png"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'wp_insta-facebook-fr'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/fr/ressources/instagram-facebook-dynamic-ads-livre-blanc/"
          >
            <img
              :src="`${options.baseUrl}/img/ban/ban_wp_insta_facebook_fr.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'wp_insta-facebook-en'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/resources/instagram-facebook-dynamic-ads-whitepaper/"
          >
            <img
              :src="`${options.baseUrl}/img/ban/ban_wp_insta_facebook_en.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'wp_insta-facebook-de'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/de/ressourcen/instagram-facebook-dynamic-ads-whitepaper/"
          >
            <img
              :src="`${options.baseUrl}/img/ban/ban_wp_insta_facebook_de.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'Sophie'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.youtube.com/watch?v=Xwyn87fHsF8"
          >
            <img
              :src="`${options.baseUrl}/img/ban/ban_video_sophie.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'Recruitment'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/careers/"
          >
            <img
              :src="`${options.baseUrl}/img/ban/banner-recrutment.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'yoox-de'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/de/ressourcen/verkaufen-sie-auf-yoox/"
          >
            <img
              :src="`${options.baseUrl}/img/ban/yoox-de.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'yoox-en'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/resources/sell-on-yoox/"
          >
            <img
              :src="`${options.baseUrl}/img/ban/yoox-en.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'yoox-es'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/es/recursos/vender-en-yoox/"
          >
            <img
              :src="`${options.baseUrl}/img/ban/yoox-es.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'yoox-fr'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/fr/ressources/vendre-sur-yoox/"
          >
            <img
              :src="`${options.baseUrl}/img/ban/yoox-fr.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'yoox-it'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/it/risorse/vendere-su-yoox/"
          >
            <img
              :src="`${options.baseUrl}/img/ban/yoox-it.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'k5-2022'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.k5.de/events/k5-konferenz"
          >
            <img
              :src="`${options.baseUrl}/img/ban/k5-future-retail-conference-2022.jpg`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'marktplatzwelt-2022'">
        <td width="550">
          <a
            target="_blank"
            href="https://gominga.com/de/marktplatzwelt-2022/"
          >
            <img
              :src="`${options.baseUrl}/img/ban/MPL_Newsletter_Teaser_1200x400.jpg`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'loreal-luxe'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/resources/loreal-luxe-case-study-2"
          >
            <img
              :src="`${options.baseUrl}/img/ban/signature_loreal_luxe.jpeg`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'pc-componentes'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/integrations/pc-componentes/"
          >
            <img
              :src="`${options.baseUrl}/img/ban/PCcomponentes-Unilae-Signature-mail.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'google-ebook'">
        <td width="550">
          <a
            target="_blank"
            href="https://hello.lengow.com/report/google-ebook/11-2022-15"
          >
            <img
              :src="`${options.baseUrl}/img/ban/google-ebook.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'google-webinar'">
        <td width="550">
          <a
            target="_blank"
            href="https://www.lengow.com/fr/ressources/google-lia-omnicanalite"
          >
            <img
              :src="`http://localhost:8080/img/ban/ban_google_webinar.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'greetings-2023' && options.company.selected === 'Lengow'">
        <td width="550">
          <a
            target="_blank"
            href="https://vimeo.com/780405272"
          >
            <img
              :src="`${options.baseUrl}/img/ban/Mail_signature_2023greetings_banner.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'greetings-2023' && options.company.selected === 'Netrivals'">
        <td width="550">
          <a
            target="_blank"
            href="https://vimeo.com/781062256"
          >
            <img
              :src="`${options.baseUrl}/img/ban/Mail_signature_2023greetings_banner.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'Challenge-2023'">
        <td width="550">
          <a
            target="_blank"
            href="https://hello.lengow.com/e-commerce-challenges-2023"
          >
            <img
              :src="`${options.baseUrl}/img/ban/Challenges_2023_signature_ebook_download_EN.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <tr v-if="options.banner.default === 'Xpert-talk-challenges-2023'">
        <td width="550">
          <a
            target="_blank"
            href="https://hello.lengow.com/how-to-master-the-e-commerce-challenges-2023?hsLang=en"
          >
            <img
              :src="`${options.baseUrl}/img/ban/Xpertalks_Challenges_2023_Register_EN.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr>
      <!-- <tr v-if="options.banner.default === 'apero'">
        <td width="550">
          <a
            target="_blank"
            href="https://forms.gle/xJWsHCqBZRpPRW1FA"
          >
            <img
              :src="`${options.baseUrl}/img/ban/apero_rooftop_2022.png`"
              alt="banner"
              style="margin-top: 15px; max-width: 550px"
            >
          </a>
        </td>
      </tr> -->
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Banner',

  computed: {
    ...mapState(['options'])
  }
}
</script>
